import { makeStyles } from '@mui/styles';

const ROW_HEIGHT = 62;

export default makeStyles((theme) => ({
  '@global': {
    '.ag-theme-material': {
      '&': `
        --ag-row-height: ${ROW_HEIGHT}px;
        `,
      '& .ag-row': {
        maxHeight: ROW_HEIGHT,
      },

      '& .ag-column-first': {
        borderRadius: '8px 0 0 8px',
        borderLeft: '1px solid var(--ag-border-color)',
      },

      '& .ag-column-last': {
        borderRadius: '0 8px 8px 0',
        borderRight: '1px solid var(--ag-border-color) !important',
      },

      '& .ag-center-cols-container': {
        '& .ag-row .ag-cell': {
          padding: theme.spacing(0, 0.5),
        },
      },

      // when editing - hide current value of cell
      '& .ag-cell-popup-editing': {
        opacity: 0,
      },
    },
  },
}));
