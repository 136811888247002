// @flow
import React, { useCallback, memo, useState, useEffect, useRef, useMemo } from 'react';

import Chip from '@mui/material/Chip';
import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ApproverLevels from 'pages/components/ApproverLevels';
import { TextField, Autocomplete } from './StyledComponents';

import useApprovalsCellData from '../useApprovalsCellData';

import type { TApproveByCell } from 'components/AgGrid/VendorBasedApprovals/types';
import type { TGridApi, Column } from 'pages/company/Grid/types.js.flow';

type TProps = {
  value: TApproveByCell,
  api: TGridApi,
  column: Column,
  colDef: any,
};

const ApprovalsCellRenderer: React$StatelessFunctionalComponent<TProps> = (props) => {
  const { colDef, column, api, value } = props;
  const { adoptedData, label } = useApprovalsCellData(value);
  const [width, setWidth] = useState(column.getActualWidth());
  const [previewTags, setPreviewTags] = useState([]);
  const approversContainerRef = useRef(null);

  const isEditable = typeof colDef.editable === 'function' ? colDef.editable(props) : colDef.editable;

  const tileSize = useMemo(() => [...adoptedData].splice(previewTags.length).length, [previewTags, adoptedData]);

  const renderTags = useCallback(
    () =>
      value.advanced_approval_group_id ? (
        <ApproverLevels value={adoptedData} maxWidth={width} hideExtra considerChipWidth />
      ) : (
        <Stack
          ref={approversContainerRef}
          direction="row"
          gap={1}
          alignItems="center"
          height={32}
          flexWrap="nowrap"
          width="100%"
        >
          {previewTags.map(({ picture, fullName, id }, i) => (
            <Approver
              key={id}
              avatar={<Avatar alt={fullName} src={picture} />}
              label={fullName}
              variant="outlined"
              hasArrow={i + 1 !== adoptedData.length}
            />
          ))}
          {!!tileSize && <Chip size="small" label={`+${tileSize}`} />}
        </Stack>
      ),
    [value.advanced_approval_group_id, adoptedData, width, previewTags, tileSize],
  );

  const handleResize = useCallback(() => {
    setWidth(column.getActualWidth());
  }, [column]);

  useEffect(() => {
    handleResize();
    api.addEventListener('columnResized', handleResize);
    return () => {
      api.removeEventListener('columnResized', handleResize);
    };
  }, [api, handleResize]);

  useEffect(() => {
    const tags = [];
    if (approversContainerRef.current && !value.advanced_approval_group_id) {
      const wrapperWidth = approversContainerRef.current.getBoundingClientRect().width;
      let tw = 65; // approximate chip width
      adoptedData.forEach((tag) => {
        const string = tag.fullName;
        tw += string.length * 6 + 70;
        if (tw < wrapperWidth) {
          tags.push(tag);
        }
      });
    }
    setPreviewTags(tags);
  }, [adoptedData, value.advanced_approval_group_id, width]);

  return (
    <Autocomplete
      open
      value={adoptedData}
      options={[]}
      renderInput={(params) => <TextField label={label} {...params} inputProps={{ ...params.inputProps }} />}
      renderTags={renderTags}
      readOnly
      disabled={!isEditable}
      fullWidth
      autoComplete
      disablePortal
      disableClearable
      disableCloseOnSelect
      multiple
    />
  );
};

export default memo(ApprovalsCellRenderer);
