// @flow
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Api from 'domain/api';
import flow from 'lodash/fp/flow';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import { updateVendorBasedApprovalAction } from 'domain/approvals';

import { AgGridReact } from 'ag-grid-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import useAgGridProps from 'components/AgGrid/VendorBasedApprovals/useAgGridProps';
import useColumnTypes from 'components/AgGrid/VendorBasedApprovals/useAgGridColumnTypes';
import useFetchColumnDefs from 'components/AgGrid/VendorBasedApprovals/useFetchColumnDefs';
import { addTooltipPropsForColDefs } from 'components/AgGrid/VendorBasedApprovals/helpers';

import MultipleDropdownCellEditor from 'pages/configurations/company/pages/approvals/pages/vendorBasedApproval/components/MultipleDropdownCellEditor';
import MultipleDropdownCellRenderer from 'pages/configurations/company/pages/approvals/pages/vendorBasedApproval/components/MultipleDropdownCellRenderer';
import AgGridCustomTooltip from 'pages/company/Grid/components/CustomTooltip';
import ApprovalsCellRenderer from './components/ApprovalsCellRenderer';
import ApprovalsCellEditor from './components/ApprovalsCellEditor';

// inject order important for styles
import useAgGridGlobalTheme from 'components/AgGrid/hooks/useAgGridGlobalTheme';
import useAgGridStyles from 'components/AgGrid/VendorBasedApprovals/useAgGridStyles';

const VendorBasedApproval = () => {
  useAgGridGlobalTheme();
  useAgGridStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const agGridDefaultProps = useAgGridProps({ rowHeight: 72 });
  const columnTypes = useColumnTypes();

  const { rawColumnDefs, rawColumnDefsIsBusy } = useFetchColumnDefs();

  const columnDefs = useMemo(() => flow(addTooltipPropsForColDefs)(rawColumnDefs), [rawColumnDefs]);

  const serverDatasource = useMemo(
    () => ({
      getRows: async (params) => {
        const { success, request, api } = params;
        const { startRow, endRow, filterModel, sortModel } = request;

        api.hideOverlay();

        try {
          const {
            data: { items, count },
          } = await Api.getVendorBasedApprovalsRows({
            data: {
              startRow,
              endRow,
              filterModel,
              sortModel,
            },
          });

          success({ rowData: items, rowCount: count });

          if (count === 0) {
            api.showNoRowsOverlay();
          }
        } catch (error) {
          console.log('[ERROR]: on fetch grid rows', error);
          success({ rowData: [], rowCount: 0 });
          api.showNoRowsOverlay();
        }
      },
    }),
    [],
  );

  const components = useMemo(
    () => ({
      customTooltip: AgGridCustomTooltip,
      multipleDropdownCellEditor: MultipleDropdownCellEditor,
      multipleDropdownCellRenderer: MultipleDropdownCellRenderer,
      approvalsCellRenderer: ApprovalsCellRenderer,
      approvalsCellEditor: ApprovalsCellEditor,
    }),
    [],
  );

  const getRowID = useCallback(({ data }) => data.id, []);

  const onCellValueChanged = useCallback(
    ({ api, node, colDef, data, newValue, oldValue }) => {
      if (isEqual(newValue, oldValue)) return;

      const payload = {
        ids: [data.id],
        values: { [colDef.field]: data[colDef.field] },
      };

      if (node.selected) {
        api.forEachNode((n) => {
          if (n.selected && node.id !== n.id) {
            payload.ids.push(n.id);
          }
        });
      }

      new Promise((resolve, reject) => {
        dispatch(updateVendorBasedApprovalAction({ data: payload, resolve, reject }));
      }).then((response) => {
        api.applyServerSideTransaction({ update: response });
      });
    },
    [dispatch],
  );

  return (
    <Box className="ag-theme-material" display="flex" flexDirection="column" height="100%">
      <Typography variant="subtitle1" fontWeight={500} mb={2}>
        {formatMessage({
          id: 'configurations.company.approvals.vendorBasedApproval.title',
          defaultMessage: 'Approval Flow',
        })}
      </Typography>

      {rawColumnDefsIsBusy && <LinearProgress />}
      {!rawColumnDefsIsBusy && (
        <AgGridReact
          {...agGridDefaultProps}
          serverSideDatasource={serverDatasource}
          columnTypes={columnTypes}
          columnDefs={columnDefs}
          components={components}
          getRowId={getRowID}
          onCellValueChanged={onCellValueChanged}
        />
      )}
    </Box>
  );
};

export default VendorBasedApproval;
