// @flow
import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Rest from '../RestCount';
import { Map } from 'immutable';
import type { RecordOf } from 'immutable';
import type { ChatUser } from 'domain/chat/types';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { SYMBOL_FOR_EMPTY_CELL } from 'components/AgGrid/Documents/useAgGridColumnTypes';

const [SIZE, SPACING] = ['small', 0.5];

const Wrapper = React.forwardRef((props, ref) => <Stack direction="row" flexWrap="nowrap" {...props} ref={ref} />);

interface IProps {
  value: Array<string>;
  valueFormatted: Array<string> | string;
  usersById: Map<string, RecordOf<ChatUser>>;
}

interface IState {
  hiddenItems: string[];
}

class TagsCellRenderer extends React.Component<IProps, IState> {
  elements: { [key: string]: any } = {};

  observers: { [key: string]: IntersectionObserver } = {};

  boxEl: Element = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      hiddenItems: [],
    };
  }

  componentDidMount() {
    setTimeout(this.initObservers, 0);
  }

  componentWillUnmount() {
    Object.values(this.observers).forEach((o) => {
      o.disconnect();
    });
  }

  get tagList() {
    const { value } = this.props;

    return value ? value.sort((tagA, tagB) => this.isUserTag(tagB) - this.isUserTag(tagA)) : [];
  }

  isUserTag = (tag) => {
    const { usersById } = this.props;

    return Boolean(usersById.get(tag));
  };

  getVisibleKeys = () => this.tagList.filter((tag) => !this.isHiddenKey(tag));

  getTagsStat = () => {
    const visibleTags = this.getVisibleKeys();
    const count = this.tagList.length;
    const countVisible = visibleTags.length;

    const restCount = count - countVisible;
    const lastKey = count ? this.tagList[count - 1] : null;
    const lastVisibleKey = countVisible && countVisible !== count ? visibleTags[countVisible - 1] : null;

    return { count, countVisible, lastVisibleKey, restCount, lastKey };
  };

  setItemRef = (key: string) => (ref) => {
    this.elements[key] = ref;
  };

  setBoxEl = (el: Element) => {
    this.boxEl = el;
  };

  initObservers = () => {
    const observerOptions = { root: this.boxEl, rootMargin: '0px', threshold: 1 };
    Object.entries(this.elements).forEach(([key, el]) => {
      if (get(el, 'nodeName')) {
        const observer = new IntersectionObserver(this.handleObserver(key), observerOptions);
        observer.observe(el);
        this.observers[key] = observer;
      }
    });
  };

  isHiddenKey = (key) => {
    const { hiddenItems } = this.state;

    return hiddenItems.includes(key);
  };

  showItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (this.isHiddenKey(key)) {
      this.setState({ hiddenItems: hiddenItems.filter((item) => item !== key) });
    }
  };

  hideItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (!this.isHiddenKey(key)) {
      this.setState({ hiddenItems: [...hiddenItems, key] });
    }
  };

  handleObserver =
    (key: string) =>
    ([intersectionObject]) => {
      // when scrolling inside ag-grid we are getting false observer handler invocation
      // with root element (grid cell) having all dimentions from getBoundingCLientRekt equal to 0
      // though cell element is present in DOM and has proper sizing and positioning
      if (intersectionObject.rootBounds.width === 0 && intersectionObject.rootBounds.height === 0) return;
      const action = intersectionObject.intersectionRatio === 1 ? this.showItem : this.hideItem;
      action(key);
    };

  render() {
    const { lastVisibleKey, countVisible, restCount } = this.getTagsStat();
    const { usersById, valueFormatted } = this.props;

    return this.tagList.length > 0 ? (
      <Wrapper maxWidth="100%" spacing={SPACING} ref={this.setBoxEl} sx={{ px: 0.5 }}>
        {!countVisible && Boolean(restCount) && <Rest size={SIZE} label={restCount} />}
        {this.tagList.map((tag) => {
          const userTag = usersById.get(tag);

          const props = {
            label: userTag ? userTag.username : tag,
            avatar: userTag ? <Avatar src={userTag.picture} alt={userTag.username} /> : undefined,
          };

          return (
            <Wrapper spacing={SPACING} key={tag} ref={this.setItemRef(String(tag))}>
              <Chip
                sx={{ backgroundColor: 'common.white', opacity: this.isHiddenKey(tag) ? 0 : 1 }}
                {...props}
                size={SIZE}
                color="primary"
                variant="outlined"
              />
              {lastVisibleKey === tag && <Rest size={SIZE} label={restCount} />}
            </Wrapper>
          );
        })}
      </Wrapper>
    ) : (
      <Box sx={{ px: 2 }}>{isEmpty(valueFormatted) ? SYMBOL_FOR_EMPTY_CELL : valueFormatted}</Box>
    );
  }
}

const mapStateToProps = (state) => ({
  usersById: chatAllUsersByIdSelector(state),
});

export default compose(connect(mapStateToProps))(TagsCellRenderer);
