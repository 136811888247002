import { makeStyles } from '@mui/styles';
import { GRID_SERVICE_KEYS } from 'components/AgGrid/helpers';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'components/AgGrid/Documents/agGridThemeClasses';
import { alpha } from '@mui/material/styles';

const {
  PREVIEW_CHECKBOX_COLUMN_NAME,
  PREVIEW_BTN_COLUMN_NAME,
  COLUMNS_VISIBILITY_MENU,
  CONTEXT_MENU_COLUMN_NAME,
  COLUMNS_LINKED_ICON,
} = GRID_SERVICE_KEYS;

export default makeStyles((theme) => {
  const isRtl = theme.direction === 'rtl';
  const LEFT = isRtl ? 'right' : 'left';
  const RIGHT = isRtl ? 'left' : 'right';

  return {
    '@global': {
      '.ag-theme-material': {
        '& .ag-root-wrapper-body': {
          paddingRight: theme.spacing(2),
        },

        '& .ag-overlay': {
          pointerEvents: 'all',

          '& .ag-react-container': {
            justifyContent: 'center',

            '& .MuiDivider-root': {
              display: 'none',
            },
          },
        },

        '& .ag-selection-checkbox': {
          marginLeft: theme.spacing(1),
          marginRight: 0,
        },

        // ======== STYLES FOR HEADER PINNED COLUMNS
        '& .ag-header': {
          [`& .ag-pinned-${LEFT}-header`]: {
            '& .ag-header-cell': {
              '&:nth-child(2)': {
                borderRadius: '8px 0 0 8px',
                border: '1px solid var(--ag-border-color)',
                borderRight: 'none',
              },
            },
          },

          [`& .ag-pinned-${RIGHT}-header`]: {
            border: 'none',
          },
        },

        '& .ag-header-cell': {
          [`&.${COLUMNS_VISIBILITY_MENU}`]: {
            padding: theme.spacing(0, 0.5),
            borderRadius: '0 8px 8px 0',
            border: '1px solid var(--ag-border-color) !important',
          },

          [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
            display: 'none',
          },

          [`&.${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            flexDirection: 'row-reverse',
          },

          '& .ag-header-select-all': {
            margin: 0,
          },
        },

        '& .ag-cell': {
          [`&.${COLUMNS_VISIBILITY_MENU}`]: {
            borderRight: '1px solid var(--ag-border-color) !important',
            borderRadius: '0 8px 8px 0',
          },

          [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
            opacity: 0,
            border: 0,
            backgroundColor: 'transparent',

            // context menu icon stay visible when menu is open
            [`&.${IS_OPEN_CONTEXT_MENU_CLASS}`]: {
              opacity: '1!important',
            },
          },

          [`&.${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            '& .ag-cell-wrapper': {
              flexDirection: 'row-reverse',
            },
          },

          [`&[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"],
            &[col-id="${PREVIEW_BTN_COLUMN_NAME}"],
            &[col-id="${CONTEXT_MENU_COLUMN_NAME}"],
            &[col-id="${COLUMNS_LINKED_ICON}"],
            &[col-id="${COLUMNS_VISIBILITY_MENU}"]`]: {
            '& .ag-range-handle': {
              width: 0,
            },
          },
        },

        '& .ag-row-group-expanded': {
          [`& .${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            paddingLeft: 0,

            '& .ag-cell-wrapper': {
              borderLeft: `2px solid ${theme.palette.common.black}`,
              borderRadius: '4px 0 0 4px',
            },
          },
        },

        // ======== STYLES FOR ROW
        '& .ag-row': {
          '&.ag-row-hover': {
            '& .ag-cell': {
              [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
                opacity: 1,
                backgroundColor: 'transparent',
              },
            },
          },

          '&.row-has-error': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.error.main, theme.palette.action.activatedOpacity)}`,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.error.main,
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.error.main,
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },

          '&.row-has-warning': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.warning.main, theme.palette.action.activatedOpacity)}`,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.warning.main,
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.warning.main,
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },

          '&.row-publish-scheduling': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.grey[600], theme.palette.action.activatedOpacity)}`,
              color: theme.palette.text.disabled,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.grey[600],
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.grey[600],
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },
        },

        [`& .ag-pinned-${LEFT}-cols-container`]: {
          '& .ag-row': {
            '& .ag-cell': {
              '&:nth-child(2)': {
                borderRadius: '8px 0 0 8px',
                border: '1px solid var(--ag-border-color)',
                borderRight: 'none',
              },
            },
          },
        },
      },
    },
  };
});
