import { useCallback, useImperativeHandle } from 'react';
import { isServiceColumn } from 'components/AgGrid/helpers';
import { generatePath } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import Api from 'domain/api';

const usePropagateGridApiRef = (props) => {
  const { ref, gridApiRef, gridContext, selectedIDs } = props;

  const exportAs = useCallback(
    async (format = 'csv', onlySelected = false) => {
      const { filter, sort } = gridApiRef.current.getState();
      const allColumns = gridApiRef.current?.getAllDisplayedColumns() || [];
      // for deep column data like warning we have naming(path to value) warning.status, so that we can immediately take desired value
      // send to back-end origin column id colId.split('.')[0] - warning
      const columnKeys = allColumns
        .filter(({ colId }) => !isServiceColumn(colId))
        .map(({ colId, colDef }) => ({ field: colId.split('.')[0], headerName: colDef.headerName, type: colDef.type }));
      try {
        const {
          data: { task_id: taskId },
        } = await Api.gridDocumentsExport({
          data: {
            format,
            headers: columnKeys,
            document_ids: onlySelected ? selectedIDs.toList() : [],
            filters: {
              filterModel: filter?.filterModel || {},
              sortModel: sort?.sortModel || [],
              ...gridContext.fetchParams,
            },
          },
        });

        const path = generatePath(ROUTES_PATH.DEFERRED_TASK.absolute, { taskId });
        const params = new URLSearchParams({ type: 'workspaceExport' });
        const url = `${path}?${params}`;

        window.open(url, '_blank');
      } catch (e) {
        console.error(e);
      }
    },
    [gridApiRef, gridContext, selectedIDs],
  );

  useImperativeHandle(ref, () => ({
    exportAllRowsDataAsExcel: () => exportAs('xls'),
    exportSelectedRowsDataAsExcel: () => exportAs('xls', true),
    exportAllRowsDataAsCsv: () => exportAs(),
  }));
};

export default usePropagateGridApiRef;
