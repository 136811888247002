// @flow
import React, { useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { arrayMove } from '@dnd-kit/sortable';

import MenuItem from '@mui/material/MenuItem';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from 'components/mui/Tooltip';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ApproverLevels from 'pages/components/ApproverLevels';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import SortableList from 'pages/document/DocumentTopPanel/approval/dnd/SortableList';
import Approver from 'pages/components/Approver';
import { TextField } from './StyledComponents';

import { filterOptions, messages } from 'hooks/approval/useApprovals';
import useApprovalsCellData from '../useApprovalsCellData';

import type { TApproveByCell } from 'components/AgGrid/VendorBasedApprovals/types';

import { createStyles, makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const APPROVALS_LIMIT = 8;

const MenuList = styled('ul')(() => ({ padding: 0 }));

type TProps = {
  onValueChange: (value: TApproveByCell) => void,
  value: TApproveByCell,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyTags: {
      bodyTags: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 100%',
      },
    },
    approvalDraggableElem: {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

const ApprovalsCellEditor: React$StatelessFunctionalComponent<TProps> = ({ onValueChange, value }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { adoptedData, approverIds, approvers } = useApprovalsCellData(value);

  const getWidth = useCallback(() => {
    const gridContainer = document.querySelector('.ag-root');
    return gridContainer ? gridContainer.offsetWidth : 600;
  });

  const handleChange = useCallback(
    (e, selected, reason, selectedOption) => {
      if (reason === 'clear') {
        onValueChange({
          approver_ids: [],
          basic_approval_group_id: '',
          advanced_approval_group_id: '',
        });
      } else {
        const isAdvancedGroup = !!selectedOption.option?.levels;
        const groupId = selectedOption.option?.group_id;
        const newValues = isAdvancedGroup
          ? []
          : selected.reduce((acc, option) => {
              if (option.flowType) {
                return acc;
              }
              if (option?.approvers) {
                option.approvers.forEach((approver) => {
                  if (!acc.some((item) => item === approver.id)) {
                    acc.push(approver.id);
                  }
                });
              } else {
                acc.push(option.id);
              }
              return acc;
            }, []);

        const isBasicGroup = (value.advanced_approval_group_id || selected.length === 1) && groupId;

        onValueChange({
          approver_ids: isBasicGroup ? [] : newValues,
          basic_approval_group_id: isBasicGroup ? groupId : '',
          advanced_approval_group_id: isAdvancedGroup ? selectedOption.option.id : '',
        });
      }
    },
    [onValueChange, value],
  );

  const handleDelete = useCallback(
    (id) => {
      onValueChange({
        ...value,
        approver_ids: approverIds.filter((approver) => approver !== id),
        basic_approval_group_id: '',
      });
    },
    [onValueChange, value, approverIds],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      const changedPosition = oldIndex !== newIndex;

      onValueChange({
        ...value,
        approver_ids: changedPosition ? arrayMove(approverIds, oldIndex, newIndex) : value.approver_ids,
        basic_approval_group_id: changedPosition ? '' : value.basic_approval_group_id,
      });
    },
    [onValueChange, value, approverIds],
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        autoFocus
        variant="standard"
        size="small"
        label={formatMessage({
          id: 'configurations.company.approvals.groups.select.placeholder',
          defaultMessage: 'Select approvers...',
        })}
      />
    ),
    [formatMessage],
  );

  const renderOption = useCallback((params, option, { selected, disabled }) => {
    if (option.group_id) {
      return (
        <MenuItem {...params} selected={selected} disabled={disabled} sx={{ gap: 2 }}>
          <AvatarGroup max={8}>
            {option.approvers.map((a) => (
              <Tooltip key={a.id} t={a.fullName}>
                <Avatar src={a.picture} alt={a.fullName} sx={{ width: 24, height: 24 }} />
              </Tooltip>
            ))}
          </AvatarGroup>
          <ListItemText>{option.group_title}</ListItemText>
        </MenuItem>
      );
    }

    if (option.levels) {
      return (
        <MenuItem {...params} {...{ selected, disabled }} sx={{ gap: 2 }}>
          <ApproverLevels value={option.levels} collapseAll />
          <ListItemText>{option.name}</ListItemText>
        </MenuItem>
      );
    }

    return (
      <MenuItem {...params} {...{ selected, disabled }}>
        <ListItemText>
          <Stack alignItems="center" direction="row" gap={1}>
            <Avatar src={option.picture} alt={option.fullName} sx={{ width: 24, height: 24 }} />
            {option.fullName}
            <br />({option.tag})
          </Stack>
        </ListItemText>
      </MenuItem>
    );
  }, []);

  const renderTags = useCallback(
    (selected) =>
      value.advanced_approval_group_id ? (
        <ApproverLevels value={adoptedData} expandAll />
      ) : (
        <SortableList
          items={selected}
          className={classes.bodyTags}
          idKey="id"
          onSortOver={() => {}}
          onSortEnd={onSortEnd}
          Element={(props) => (
            <Stack gap={1} flexWrap="wrap" direction="row" {...props}>
              {props.children}
            </Stack>
          )}
          helperClass={classes.approvalDraggableElem}
        >
          {({ fullName, picture, id, className, style }) => (
            <Approver
              avatar={<Avatar alt={fullName} src={picture} />}
              label={fullName}
              variant="outlined"
              onDelete={() => {
                handleDelete(id);
              }}
              className={className}
              style={style}
              hasArrow={false}
            />
          )}
        </SortableList>
      ),
    [value.advanced_approval_group_id, adoptedData, classes, handleDelete, onSortEnd],
  );

  const renderGroup = useCallback(
    (params) => (
      <li key={params.group}>
        <ListSubheader component="div" sx={{ top: (theme) => theme.spacing(-1), zIndex: 10 }}>
          <Typography variant="overline" color="primary">
            {formatMessage(messages[params.group], { count: params.children.length })}
          </Typography>
        </ListSubheader>
        <MenuList>{params.children}</MenuList>
      </li>
    ),
    [formatMessage],
  );

  const getOptionDisabled = useCallback(
    (o) =>
      !!(
        (o.group_id &&
          (o.approvers.every((a) => value.approver_ids.some((v) => v === a.id)) ||
            o.group_id === value.basic_approval_group_id)) ||
        (o.id && o.id === value.advanced_approval_group_id)
      ),
    [value],
  );

  return (
    <Autocomplete
      fullWidth
      open
      disableCloseOnSelect
      disableListWrap
      filterSelectedOptions
      margin="normal"
      noOptionsText={
        value.approver_ids.length + 1 <= APPROVALS_LIMIT || !approvers.length ? (
          <FormattedMessage
            id="document.approval.noSuggestions"
            defaultMessage="No more users available for selection"
          />
        ) : (
          <FormattedMessage
            id="document.approval.limitReached"
            defaultMessage={`${value.approver_ids.length} users limit exceeded`}
            values={{ limit: value.approver_ids.length }}
          />
        )
      }
      options={value.approver_ids.length + 1 <= APPROVALS_LIMIT ? approvers : []}
      getOptionLabel={(o) => o.group_id || o.id}
      groupBy={(option) => option.group}
      value={adoptedData}
      onChange={handleChange}
      filterOptions={filterOptions}
      disablePortal
      multiple
      sx={{ minWidth: getWidth(), bgcolor: 'common.white' }}
      renderInput={renderInput}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      renderTags={renderTags}
      renderGroup={renderGroup}
    />
  );
};

export default memo(ApprovalsCellEditor);
