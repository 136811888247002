// @flow
import { useMemo } from 'react';
import useApprovals from 'hooks/approval/useApprovals';

const getLevels = (approvers, advancedGroupId) => {
  const advancedGroup = approvers.find(({ id }) => id === advancedGroupId);
  if (advancedGroup) {
    return { data: advancedGroup.levels || [], label: advancedGroup.name };
  }
  return [];
};

const getApproversData = (ids, approvers) =>
  approvers.length ? ids.map((id) => approvers.find((a) => a.id === id)) : [];

const getData = (value, approvers) => {
  if (value.basic_approval_group_id) {
    const group = approvers.find((a) => a.group_id === value.basic_approval_group_id);
    return { data: group?.approvers || [], label: group?.group_title || '' };
  }
  return { data: getApproversData(value.approver_ids, approvers), label: '' };
};

export default (value) => {
  const { approvers } = useApprovals();

  const { adoptedData, approverIds, inputLabel } = useMemo(() => {
    const { data, label } = value.advanced_approval_group_id
      ? getLevels(approvers, value.advanced_approval_group_id)
      : getData(value, approvers);
    const ids = value.basic_approval_group_id ? data.map(({ id }) => id) : value.approver_ids;

    return { adoptedData: data || [], approverIds: ids, inputLabel: label };
  }, [value, approvers]);

  return { adoptedData, approverIds, approvers, label: inputLabel };
};
