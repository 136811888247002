import { useMemo } from 'react';

const useAgGridColumnTypes = () =>
  useMemo(
    () => ({
      boolean: {
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
      },
      select: {},
      multi_select: {
        cellEditor: 'multipleDropdownCellEditor',
        cellRenderer: 'multipleDropdownCellRenderer',
        cellEditorPopup: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      advanced_approval_set: {
        cellRendererParams: {
          needContainerForDetectOverflow: true,
        },
        cellEditorPopup: true,
        cellEditor: 'approvalsCellEditor',
        cellRenderer: 'approvalsCellRenderer',
        minWidth: 500,
      },
    }),
    [],
  );

export default useAgGridColumnTypes;
