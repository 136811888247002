import type { RecordFactory } from 'immutable';
import type {
  TDocumentHotkey,
  THotkeyMatch,
  THotkeyMatchCellListItem,
  THotkeysState,
  TValidatedDocument,
  TViewinfo,
  TParamsFactory,
  TQueueItem,
  TCachedDocument,
  TSupplierPageTokens,
  TSupplierStateDocCounts,
  TDocument,
  TDocuments,
  TGridApplyTransaction,
  TApprovalStamp,
} from 'domain/documents/types.js.flow';
import { List, Map, OrderedMap, OrderedSet, Record, Set } from 'immutable';
import { RecordCellSet } from 'domain/journal/helper';
import { NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';

function createViewInfo() {
  return {
    pages: 0,
    rotations: new List(),
  };
}

export const ViewinfoFactory: RecordFactory<TViewinfo> = new Record(createViewInfo());

export const ParamsFactory: RecordFactory<TParamsFactory> = new Record({
  category: null,
  creationDate: null,
  subject: null,
  uploadText: null,
  ccEmailList: null,
  linkID: null,
});

export const UploadQueueItemFactory: RecordFactory<TQueueItem> = new Record({
  id: '',
  path: '',
  status: 'pending',
  progress: 0,
  cancelToken: null,
  file: null,
  params: ParamsFactory(),
  isConfidential: false,
});

export const CachedDocumentFactory: RecordFactory<TCachedDocument> = new Record({
  hash: null,
  downloadProgress: 0,
});

export const LinkedFactory = new Record({
  count: 0,
  pageToken: null,
  tag: null,
  list: new Map(),
  isOpen: false,
});

export const ApprovalStampFactory: RecordFactory<TApprovalStamp> = new Record({
  ll: null, // low left coordinates
  ur: null, // up right coordinates
  rotation: null,
});

export const DocumentFactory: RecordFactory<TDocument> = new Record({
  documentID: '',
  created: '',
  favorite: false,
  isAcceptable: false,
  doc: '',
  docStatus: '',
  finInfo: null,
  sourceID: null,
  versionID: null,
  folderID: null,
  new: 0,
  notes: '',
  notesColor: NOTE_PALETTE_DEFAULT_KEY,
  source: '',
  total: 0,
  type: 2,
  tags: new Set(),
  recentTags: new Set(),
  doctype: 'general',
  status: ['new'],
  reason: '',
  protected: false,
  viewinfo: ViewinfoFactory(),
  linkid: '',
  ltext: '',
  rootCategory: null,
  isConfidential: false,
  approvals: new Map(),
  gridMeta: new Map(),
  fromEmail: false,
  indicateWarning: false,
  stateColumn: null,
  canBeMoved: false,
  sign: {
    signPosition: ApprovalStampFactory(),
  },
});

export const ValidatedDocumentFactory: RecordFactory<TValidatedDocument> = new Record({
  documentID: '',
  created: '',
  doc: '',
  docStatus: '',
  finInfo: null,
  sourceID: null,
  versionID: null,
  folderID: null,
  new: 0,
  notes: '',
  notesColor: NOTE_PALETTE_DEFAULT_KEY,
  source: '',
  total: 0,
  type: 2,
  tags: new Set(),
  recentTags: new Set(),
  doctype: 'general',
  status: ['new'],
  reason: '',
  protected: false,
  viewinfo: ViewinfoFactory(),
  linkid: '',
  ltext: '',
  acceptValidationError: null,
  rootCategory: null,
  isConfidential: false,
  approvals: new Map(),
  gridMeta: new Map(),
  fromEmail: false,
  indicateWarning: false,
  lastModifiedDate: '',
  approvalsActiveGroupId: null,
  canBeMoved: false,
  sign: {
    signPosition: ApprovalStampFactory(),
  },
});

export const HotkeyFactory: RecordFactory<TDocumentHotkey> = new Record({
  keys: new List(),
  description: '',
  level: '',
});

export const HotkeyCellListFactory: RecordFactory<THotkeyMatchCellListItem> = new Record({
  set: RecordCellSet(),
  name: '',
  cell: '',
});

export const HotkeyMatchFactory: RecordFactory<THotkeyMatch> = new Record({
  key: new List(),
  cell_list: new List(),
});

export const HotkeysStateFactory: RecordFactory<THotkeysState> = new Record({
  isLoading: false,
  companyId: null,
  data: new List(),
});

const SupplierPageTokensFactory: RecordFactory<TSupplierPageTokens> = new Record({
  received: null,
  in_process: null,
  processed: null,
  paid: null,
  rejected: null,
});

const SupplierStateDocCountsFactory: RecordFactory<TSupplierStateDocCounts> = new Record({
  received: 0,
  in_process: 0,
  processed: 0,
  paid: 0,
  rejected: 0,
});

const SupplierStateDocCountsWithoutLinkPanelsFactory: RecordFactory<TSupplierStateDocCounts> = new Record({
  received: 0,
  in_process: 0,
  processed: 0,
  paid: 0,
  rejected: 0,
});

export const GridApplyTransactionFactory: RecordFactory<TGridApplyTransaction> = new Record({
  nodes: new List(), // TODO: recheck default value and type
  transactionType: null,
});

export const DocumentsFactory: RecordFactory<TDocuments> = new Record({
  list: new OrderedMap(),
  document: ValidatedDocumentFactory(),
  gridHeadersList: new List(),
  gridDocumentsByPageIndex: new Map(), // used for navigate on document page between documents from grid workspace
  editableDoc: ValidatedDocumentFactory(),
  linked: LinkedFactory(),
  linkingTag: '',
  recentTags: new Set(),
  recentTagsCompanyId: null,
  pageToken: '',
  dokkaToken: '',
  companyId: '',
  searchTags: [],
  uploadQueue: new Map(),
  erpDuplicated: new Set(),
  exportFormats: new Map(),
  exportFormatsLoaded: false,
  searchQuery: '',
  count: 0,
  processing: 0,
  company_total: 0,
  count_without_link_panels: 0,
  unreadRequests: new List(),
  unreadRequestsLoaded: false,
  viewArrangement: null,
  fetchedDocumentsTimeStamp: null,
  latestWorkerTimeStamp: null,
  gridLoader: false,
  gridFilters: null,
  gridSorting: null,
  gridColumns: null,
  gridDefaultColumns: null,
  gridPresets: [],
  gridPresetsLoaded: false,
  sharePreset: null,
  currentPresetId: null,
  gridRefreshServerSide: null, // marker for invoke grid api refreshServerSide
  gridApplyTransaction: GridApplyTransactionFactory(), // marker for invoke grid api applyServerSideTransaction
  hotkeys: HotkeysStateFactory(),
  stateColumn: null,
  supplierPageTokens: SupplierPageTokensFactory(),
  supplierStateDocCounts: SupplierStateDocCountsFactory(),
  supplierStateDocCountsWithoutLinkPanels: SupplierStateDocCountsWithoutLinkPanelsFactory(),
  cachedDocuments: new List(),
  justPublished: null,
  loaded: false,
});
