const tooltipComponentByType = {
  select: 'customTooltip',
};

const getTooltipProps = (column) => {
  const tooltipComponent = tooltipComponentByType[column.type];

  return tooltipComponent
    ? {
        tooltipComponent,
        tooltipField: column.field,
      }
    : {};
};

export const addTooltipPropsForColDefs = (columns) =>
  columns.map((column) => ({
    ...column,
    ...getTooltipProps(column),
  }));
