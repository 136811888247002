import AutocompleteMui from '@mui/material/Autocomplete';
import TextFieldMui from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const Autocomplete = styled(AutocompleteMui, { label: 'MultipleAutocompleteRenderer' })(({ theme }) => ({
  height: 32,

  '.__cell-is-readonly &': {
    opacity: 0.38,
  },

  '& .MuiAutocomplete-tag': {
    fontSize: theme.typography.pxToRem(13),
  },

  '& .MuiFormControl-root': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 8,
    height: '100%',

    '& .MuiInputBase-root': {
      height: '100%',
      flexWrap: 'nowrap',
      paddingRight: theme.spacing(6),

      '&:after, &:before': {
        display: 'none',
      },
    },

    '.ag-cell-popup-editing &': {
      display: 'none',
    },
  },
}));

// mui autocomplete used only for render +{more} options
export const TextField = styled(TextFieldMui, { label: 'AutocompleteTextField' })(() => ({
  '& .MuiInput-root': {
    '& .MuiInputBase-input': {
      display: 'none',
    },
  },
}));
