// @flow
import React, { useCallback, memo, useState, useEffect } from 'react';

import { ID_FOR_DETECT_CELL_OVERFLOW } from 'components/AgGrid/helpers';
import { TextField, Autocomplete } from 'pages/company/Grid/components/MultipleDropdownCellRenderer/StyledComponents';
import Chip from '@mui/material/Chip';

const MultipleDropdownCellRenderer = (props: any) => {
  // eslint-disable-next-line react/prop-types
  const { value, colDef, options } = props;
  const isEditable = typeof colDef.editable === 'function' ? colDef.editable(props) : colDef.editable;
  const [adaptedValues, setAdaptedValues] = useState([]);

  useEffect(() => {
    const data = value?.reduce((acc, v) => {
      const option = options.find((o) => o.id === v);

      if (option) {
        acc.push(option);
      }

      return acc;
    }, []);

    setAdaptedValues(data);
  }, [options, value]);

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip
          key={option.id}
          label={
            <span
              id={ID_FOR_DETECT_CELL_OVERFLOW}
              style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {option.label}
            </span>
          }
          size="small"
          {...getTagProps({ index })}
        />
      )),
    [],
  );

  return (
    <Autocomplete
      open
      value={adaptedValues}
      options={[]}
      renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" />}
      renderTags={renderTags}
      limitTags={1}
      readOnly
      disabled={!isEditable}
      fullWidth
      autoComplete
      disablePortal
      disableClearable
      disableCloseOnSelect
      multiple
    />
  );
};

export default memo(MultipleDropdownCellRenderer);
