import React from 'react';
import { Typography, alpha } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ChipMui from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const Chip = styled(ChipMui)(({ theme }) => ({
  height: 22,
  borderRadius: 11,
  fontSize: 11,
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  color: theme.palette.primary.main,

  '& .MuiChip-icon': {
    fontSize: 16,
  },
}));

const SearchCharactersWarning = () => (
  <Chip
    variant="filled"
    icon={<InfoIcon />}
    color="primary"
    size="small"
    label={
      <Typography variant="caption" color="primary" sx={{ display: 'block' }}>
        <FormattedMessage
          defaultMessage="at least 3 characters"
          id="company.workspace.search.minCharacterThreasholdWarning"
        />
      </Typography>
    }
  />
);


export default SearchCharactersWarning;
