// @flow
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

import type { InputBaseProps } from '@mui/material/InputBase';

type TAppBarSearchBase = {
  value: string,
  onChange: (value: string) => void,
  InputProps?: InputBaseProps,
  endAdornment?: React.Element<*>,
};

const AppBarSearchBase = React.forwardRef(({ value, onChange, InputProps, endAdornment, ...rest }: TAppBarSearchBase, ref) => {
  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState(value);

  const onChangeInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { target } = e;
    setCurrentValue(target.value);
  };

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue, onChange]);

  useEffect(() => {
    if (value.length === 0) {
      setCurrentValue('');
    }
  }, [value]);

  return (
    <TextFieldBase
      ref={ref}
      size="small"
      placeholder={intl.formatMessage({ id: 'header.search.placeholder', defaultMessage: 'Search' })}
      value={currentValue}
      onChange={onChangeInput}
      autoFocus
      fullWidth
      InputProps={{
        sx: {
          borderRadius: 16,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment,
        ...InputProps,
      }}
      sx={{ maxWidth: '40ch' }}
      {...rest}
    />
  );
});

export default AppBarSearchBase;
