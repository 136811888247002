// @flow
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { Set } from 'immutable';

import { updateQueryAction, querySelector } from 'domain/router';
import { SEARCH_MIN_CHARACTERS } from 'domain/router/helpers';
import usePrevious from 'hooks/usePrevious';

import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import SearchCharactersWarning from '../../components/SearchCharactersWarning';
import InputAdornment from '@mui/material/InputAdornment';
import TagSuggestions from 'components/mui/Layouts/components/TagSuggestions';

const TAG_PREFIX = '#';

const Search = () => {
  const dispatch = useDispatch();
  const query = useSelector(querySelector);
  const prevQuery = usePrevious(query);
  const updatedQueryTextRef = useRef([]);

  const [inputValue, setInputValue] = useState(query?.stag?.length > 0 ? query.stag.join(' ') : '');

  const search = useCallback(
    (value) => {
      const queryTags = query.tags || [];
      const stagsTrimmed = value.trim();
      const stagList = [stagsTrimmed];
      dispatch(
        updateQueryAction({
          text: updatedQueryTextRef.current,
          tags: queryTags,
          stag: stagList[0] ? stagList : undefined,
        }),
      );
      updatedQueryTextRef.current = [];
    },
    [dispatch, query],
  );

  const debounceSearch = useMemo(() => debounce(500, search), [search]);

  const onChange = useCallback(
    (value: string) => {
      if (!value.startsWith(TAG_PREFIX) && SEARCH_MIN_CHARACTERS <= value.length && (inputValue || value)) {
        debounceSearch(value);
      } else if (inputValue.length > value.length) {
        // this should remove stag from url when we left less then 3 chars in input
        // otherwise where is no way to remove it further as deleting characters
        // from input when searh length <3 doesn't affect url not to trigger request
        debounceSearch('');
      }
      setInputValue(value);
    },
    [debounceSearch, inputValue],
  );

  const onSelectSuggestion = useCallback(
    (suggestion: string) => {
      const truncatedSuggestion = suggestion.trim();
      if (truncatedSuggestion.length < 1 || truncatedSuggestion.replace(/ /g, '').length < 1) return;
      const text = Set(query.text || []);
      const tagList = Set([truncatedSuggestion]);

      updatedQueryTextRef.current = text.union(tagList).toJS();
      // when we reset input value - we trigger inputOnChangeCallback inside component and
      // after invoked debounced search we set actual query text
      setInputValue('');
    },
    [query],
  );

  useEffect(() => {
    // this is intended to expose control over input value from outside the component
    // idea being that if url params were removed using 'reset' search button on not found screen
    // we must clean input value
    // however if we cleaned query params once user went from 4 characters to 2 by deleting
    // one by one, input value of 2 chars should remain
    if (prevQuery?.stag && !query.stag) {
      if (inputValue?.length >= SEARCH_MIN_CHARACTERS) {
        setInputValue('');
      }
    }
    // eslint-disable-next-line
  }, [prevQuery?.stag, query.stag]); // shouldn't depend on input value itself

  const displayCharacterThreasholdWarning = inputValue.length < SEARCH_MIN_CHARACTERS && inputValue.length > 0;

  const characterThreasholdWarning = displayCharacterThreasholdWarning && (
    <InputAdornment position="end">
      <SearchCharactersWarning />
    </InputAdornment>
  );

  return (
    <TagSuggestions
      InputComponent={SearchBase}
      inputValue={inputValue}
      onSelect={onSelectSuggestion}
      onInputChange={onChange}
      requirePrefix={TAG_PREFIX}
      prefixOutputWithHashtag
      maxWidth="40ch"
      endAdornment={characterThreasholdWarning}
    />
  );
};

export default Search;
