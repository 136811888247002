import type { TColumnDefs } from 'pages/company/Grid/types.js.flow';
import { GRID_SERVICE_KEYS } from 'components/AgGrid/helpers';

export const DEFAULT_COLUMN_DEF = {
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  suppressNavigable: true,
  suppressMovable: true,
  lockPinned: true,
  sortable: false,
  resizable: false,
  hide: false,
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
  },
};

const COLUMNS_VISIBILITY_DEF = {
  ...DEFAULT_COLUMN_DEF,
  field: GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU,
  cellClass: GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU,
  lockPosition: 'right',
  pinned: 'right',
  maxWidth: 50,
  minWidth: 50,
  headerClass: GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU,
  headerComponentParams: {
    enableSorting: false,
  },
};

const CONTEXT_MENU_COLUMN_DEF = {
  ...DEFAULT_COLUMN_DEF,
  field: GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME,
  headerName: '',
  minWidth: 50,
  maxWidth: 50,
  cellRenderer: 'contextCellRenderer',
  lockPosition: 'left',
  pinned: 'left',
  headerClass: GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME,
  cellClass: GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME,
  type: 'actions',
  headerComponentParams: {
    service: false,
    enableMenu: false,
    enableSorting: false,
  },
};

const PREVIEW_COLUMN_DEF = {
  ...DEFAULT_COLUMN_DEF,
  field: GRID_SERVICE_KEYS.PREVIEW_BTN_COLUMN_NAME,
  cellRenderer: 'previewCellRenderer',
  lockPosition: 'left',
  pinned: 'left',
  maxWidth: 80,
  minWidth: 80,
  cellClass: GRID_SERVICE_KEYS.PREVIEW_BTN_COLUMN_NAME,
  headerClass: GRID_SERVICE_KEYS.PREVIEW_BTN_COLUMN_NAME,
  headerComponentParams: {
    enableMenu: false,
    enableSorting: false,
  },
};

const LINKED_BUTTON_COLUMN_DEF = {
  ...DEFAULT_COLUMN_DEF,
  field: GRID_SERVICE_KEYS.COLUMNS_LINKED_ICON,
  cellClass: GRID_SERVICE_KEYS.COLUMNS_LINKED_ICON,
  cellRenderer: 'linkedCellRenderer',
  lockPosition: 'left',
  pinned: 'left',
  maxWidth: 50,
  minWidth: 50,
  headerComponentParams: {
    enableMenu: false,
    enableSorting: false,
  },
};

// ====== used for workspace grid
export const addSelectionMasterDetailToFirstColumn = (columns: TColumnDefs[]): TColumnDefs[] => [
  {
    ...PREVIEW_COLUMN_DEF,
    field: GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME,
    cellRenderer: 'agGroupCellRenderer',
    cellStyle: {},
    cellClass: GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME,
    headerClass: GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME,
    headerCheckboxSelection: true, // TODO: deprecated need use new selection api
    checkboxSelection: true, // TODO: deprecated need use new selection api
  },
  ...columns,
];

export const addColumnsVisibilityColumnDef = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  ...columns,
  { ...COLUMNS_VISIBILITY_DEF },
];

export const addContextMenuColumnDef = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  { ...CONTEXT_MENU_COLUMN_DEF },
  ...columns,
];

export const addPreviewColumnDef = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  { ...PREVIEW_COLUMN_DEF },
  ...columns,
];

export const addLinkedButtonColumnRef = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  { ...LINKED_BUTTON_COLUMN_DEF },
  ...columns,
];

export const addPreviewColumnDefForInsights = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  {
    ...PREVIEW_COLUMN_DEF,
    maxWidth: 44,
    minWidth: 44,
  },
  ...columns,
];
export const addMasterViewColumnDefForInsights = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  {
    ...DEFAULT_COLUMN_DEF,
    field: GRID_SERVICE_KEYS.COLUMNS_MASTER_VIEW,
    cellClass: GRID_SERVICE_KEYS.COLUMNS_MASTER_VIEW,
    headerClass: GRID_SERVICE_KEYS.COLUMNS_MASTER_VIEW,
    cellRenderer: 'agGroupCellRenderer',
    pinned: 'left',
    lockPosition: 'left',
    maxWidth: 44,
    minWidth: 44,
    headerComponentParams: {
      enableMenu: false,
      enableSorting: false,
    },
  },
  ...columns,
];
