import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useContext, useMemo } from 'react';
import CompanyContext from 'pages/company/CompanyContext';
import { getNestedCategory } from 'domain/categories/helpers';

const useGridContext = ({ components }) => {
  const params = useParams();
  const category = getNestedCategory(params);
  const query = useQuery();
  const { companyType } = useContext(CompanyContext);
  const isConfidential = companyType === 'confidential';

  return useMemo(
    () => ({
      fetchParams: {
        isConfidential,
        category,
        ...query,
      },
      components,
    }),
    [isConfidential, query, category, components],
  );
};

export default useGridContext;
