import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import MuiAvatarGroup from '@mui/material/AvatarGroup';
import ToggleButtonGroupMui from '@mui/material/ToggleButtonGroup';

import { MAX_AVATARS_FOR_GROUPS } from './constants';

export const SMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const MenuList = styled('ul')(() => ({ padding: 0 }));

export const AvatarGroup = styled(MuiAvatarGroup)(() => ({
  '&&.MuiAvatarGroup-root .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: 15,
  },
  '& .MuiAvatar-root:nth-of-type(1)': {
    zIndex: MAX_AVATARS_FOR_GROUPS,
  },
}));

export const ToggleButtonGroup = styled(ToggleButtonGroupMui)(({ theme }) => ({
  height: 56,
  padding: 4,
  border: `1px solid ${theme.palette.divider}`,
  width: 'fit-content',

  '& .MuiButtonBase-root': {
    borderRadius: 4,
    borderColor: 'transparent',
  },

  '& .MuiButtonBase-root.Mui-selected': {
    borderColor: theme.colors.primaryLight,
  },
}));
