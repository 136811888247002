import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Api from 'domain/api';

const useFetchColumnDefs = () => {
  const dispatch = useDispatch();

  const [columnDefs, setColumnDefs] = useState([]);
  const [isBusy, setIsBusy] = useState(true);
  const [isFetched, setIsFetched] = useState(false);

  const fetchGridColumnHeaders = useCallback(async () => Api.getVendorBasedApprovalsHeaders(), []);

  useEffect(() => {
    setIsBusy(true);

    fetchGridColumnHeaders()
      .then(({ data }) => {
        setColumnDefs(data);
        setIsFetched(true);
      })
      .catch(() => {})
      .finally(() => {
        setIsBusy(false);
      });
  }, [fetchGridColumnHeaders, dispatch]);

  return {
    rawColumnDefs: columnDefs,
    rawColumnDefsIsBusy: isBusy,
    rawColumnDefsIsFetched: isFetched,
  };
};

export default useFetchColumnDefs;
