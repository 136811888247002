// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { approversSelector } from 'domain/approvals/selectors';
import type { RawApproval, ApproverType } from 'domain/approvals/types.js.flow';

import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import Rest from '../RestCount';
import Stack from '@mui/material/Stack';
import { List } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { SYMBOL_FOR_EMPTY_CELL } from 'components/AgGrid/Documents/useAgGridColumnTypes';
import ApproverLevels from 'pages/components/ApproverLevels';

const [SIZE, SPACING] = ['small', 0.25];

const ApprovalFactoryCreator =
  (approvers: List<ApproverType>) =>
  ({ username = '', status = '', userid }: RawApproval) => {
    const approver = approvers.find((u) => u.username === username);

    return {
      id: userid,
      picture: approver?.picture,
      status,
      fullName: approver?.fullName || username,
    };
  };

const Wrapper = React.forwardRef((props, ref) => <Stack direction="row" flexWrap="nowrap" {...props} ref={ref} />);

interface IProps {
  value?: RawApproval[];
  valueFormatted: RawApproval[] | string;
  approvers: List<ApproverType>;
}

interface IState {
  hiddenItems: string[];
}

class ApprovalsCellRenderer extends React.Component<IProps, IState> {
  elements: { [key: string]: any } = {};

  observers: { [key: string]: IntersectionObserver } = {};

  boxEl: Element = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      hiddenItems: [],
      width: props.column.getActualWidth(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    const { api } = this.props;
    setTimeout(this.initObservers, 0);
    if (this.isAdvancedGroups) {
      this.handleResize();
      api.addEventListener('columnResized', this.handleResize);
    }
  }

  componentWillUnmount() {
    const { api } = this.props;
    Object.values(this.observers).forEach((o) => {
      o.disconnect();
    });
    if (this.isAdvancedGroups) {
      api.removeEventListener('columnResized', this.handleResize);
    }
  }

  handleResize() {
    const { column } = this.props;
    this.setState({ width: column.getActualWidth() });
  }

  get isAdvancedGroups() {
    const { value } = this.props;
    return !!value?.levels;
  }

  getVisibleKeys = () => (this.sortedApprovalList || []).filter((node) => !this.isHiddenKey(node.id));

  getApprovalsStat = () => {
    const approvals = this.sortedApprovalList || [];
    const visibleNodes = this.getVisibleKeys();
    const count = approvals.length;
    const countVisible = visibleNodes.length;
    const restCount = count - countVisible;
    const lastKey = count ? approvals[count - 1].id : null;
    const lastVisibleKey = countVisible && countVisible !== count ? visibleNodes[countVisible - 1].id : null;

    return { count, countVisible, lastVisibleKey, restCount, lastKey };
  };

  get sortedApprovalList() {
    const { approvers, value } = this.props;

    if (this.isAdvancedGroups) {
      return value.levels.map(({ nodes: levelNodes, flow_type: flowType, ...rest }) => ({
        ...rest,
        flowType,
        approvers: levelNodes.map(ApprovalFactoryCreator(approvers)),
      }));
    }

    const ApprovalFactory = ApprovalFactoryCreator(approvers);

    return value ? value.map(ApprovalFactory) : value;
  }

  setItemRef = (key) => (ref) => {
    this.elements[key] = ref;
  };

  setBoxEl = (el: Element) => {
    this.boxEl = el;
  };

  initObservers = () => {
    const observerOptions = { root: this.boxEl, rootMargin: '0px', threshold: 1 };
    Object.entries(this.elements).forEach(([key, el]) => {
      const observer = new IntersectionObserver(this.handleObserver(key), observerOptions);
      observer.observe(el);
      this.observers[key] = observer;
    });
  };

  isHiddenKey = (key) => {
    const { hiddenItems } = this.state;

    return hiddenItems.includes(key);
  };

  showItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (this.isHiddenKey(key)) {
      this.setState({ hiddenItems: hiddenItems.filter((item) => item !== key) });
    }
  };

  hideItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (!this.isHiddenKey(key)) {
      this.setState({ hiddenItems: [...hiddenItems, key] });
    }
  };

  handleObserver =
    (key: string) =>
    ([intersectionObject]) => {
      // when scrolling inside ag-grid we are getting false observer handler invocation
      // with root element (grid cell) having all dimentions from getBoundingCLientRekt equal to 0
      // though cell element is present in DOM and has proper sizing and positioning
      if (intersectionObject.rootBounds.width === 0 && intersectionObject.rootBounds.height === 0) return;
      const action = intersectionObject.intersectionRatio === 1 ? this.showItem : this.hideItem;
      action(key);
    };

  render() {
    const { getApprovalsStat, sortedApprovalList, isAdvancedGroups } = this;
    const { valueFormatted } = this.props;
    const { width } = this.state;
    const { lastVisibleKey, countVisible, restCount, lastKey } = getApprovalsStat();

    return sortedApprovalList ? (
      <>
        {isAdvancedGroups ? (
          <ApproverLevels value={sortedApprovalList} maxWidth={width} withStatus hideExtra />
        ) : (
          <Wrapper spacing={SPACING} maxWidth="100%" ref={this.setBoxEl} sx={{ px: 0.5 }}>
            {!countVisible && <Rest size={SIZE} label={restCount} />}
            {sortedApprovalList.map(({ fullName, picture, id, status }) => (
              <Wrapper key={id} ref={this.setItemRef(id)} spacing={SPACING}>
                <Approver
                  size={SIZE}
                  spacing={SPACING}
                  sx={{ opacity: this.isHiddenKey(id) ? 0 : 1 }}
                  avatar={<Avatar src={picture} alt={fullName} />}
                  status={status}
                  label={fullName}
                  hasArrow={lastKey !== id}
                />
                {lastVisibleKey === id && <Rest size={SIZE} label={restCount} />}
              </Wrapper>
            ))}
          </Wrapper>
        )}
      </>
    ) : (
      <Box sx={{ px: 2 }}>{isEmpty(valueFormatted) ? SYMBOL_FOR_EMPTY_CELL : valueFormatted}</Box>
    );
  }
}

const mapStateToProps = (state) => ({
  approvers: approversSelector(state),
});

export default compose(connect(mapStateToProps))(ApprovalsCellRenderer);
