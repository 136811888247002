/* @flow */

import { type TLabelOptions } from '../type.js.flow';
import { getDefaultLocale } from '../locale';

import fi from './images/favicon.ico';
import fiAt from './images/apple-touch-icon.png';
import fi16 from './images/favicon-16x16.png';
import fi32 from './images/favicon-32x32.png';
import logotype from './images/logotype.svg';
import whiteLogo from './images/whiteLogo.svg';

export const options: TLabelOptions = {
  name: 'Dokka',
  title: 'DOKKA AP',
  theme: 'dokka',
  site: 'https://www.dokka.com/',
  supportEmail: 'support@dokka.com',
  termsAndConditionLink: 'https://www.dokka.com/terms-conditions/',
  privacyLink: 'https://www.dokka.com/privacy-policy/',
  helpdeskLink: 'https://dokka-ai.zendesk.com/hc/en-us/categories/360001129852-General',
  onesignalAppId: '55a780ef-ab11-4f29-a1a0-e674ec44d098',
  appStoreDownloadLink: 'https://apps.apple.com/us/app/dokka/id1445350786?ls=1',
  playMarketDownloadLink: 'https://play.google.com/store/apps/details?id=com.dokka',
  showLogoInSidebar: null,
  primaryLocale: getDefaultLocale(),
  fi,
  fiAt,
  fi16,
  fi32,
  logotype: {
    src: logotype,
    alt: 'Dokka logo',
  },
  whiteLogo: {
    src: whiteLogo,
    alt: 'Dokka logo',
  },
};

export default options;
