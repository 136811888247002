// @flow
import React, { useEffect, useState, useRef } from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Approver from 'pages/components/Approver';
import Tooltip from 'components/mui/Tooltip';
import AvatarGroup from '@mui/material/AvatarGroup';
import Chip from '@mui/material/Chip';

import type { ApprovalLevelsWithUserData } from 'domain/approvals/types';

type TApproverLevels = {
  value: ApprovalLevelsWithUserData,
  withStatus?: boolean,
  expandAll?: boolean,
  hideExtra?: boolean,
  collapseAll?: boolean,
  considerChipWidth?: boolean,
  maxWidth?: number | string,
  direction?: 'row' | 'column',
};

const getLabel = ({ isSingleApprover, withStatus, expandAll, approvers, status, flowType, collapseAll, direction }) => {
  if (expandAll || (withStatus && (status === 'pending' || isSingleApprover) && !collapseAll)) {
    return (
      <Stack direction={direction} spacing={1} flexWrap="nowrap" alignItems="center" overflow="inherit">
        {approvers.map((a, j) => (
          <Approver
            key={j}
            arrowDirection={direction}
            avatar={<Avatar alt={a.fullName} src={a.picture} />}
            status={withStatus ? a.status : 'draft'}
            label={a.fullName}
            variant="outlined"
            hasArrow={j + 1 !== approvers.length}
            arrowIconType={flowType}
            size="small"
          />
        ))}
      </Stack>
    );
  }

  if (isSingleApprover && !collapseAll) {
    return approvers[0].fullName;
  }

  return (
    <AvatarGroup
      max={3}
      sx={{
        mx: -1,
        '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
        '& .MuiAvatar-root:nth-of-type(1)': {
          zIndex: approvers.length,
        },
      }}
    >
      {approvers.map((a, j) => (
        <Tooltip key={a.id} t={a.fullName}>
          <Avatar src={a.picture} alt={a.fullName} sx={{ zIndex: j }} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

const ApproverLevels: React$StatelessFunctionalComponent<TApproverLevels> = ({
  value,
  withStatus = false,
  expandAll = false,
  hideExtra = false,
  collapseAll = false,
  maxWidth = '100%',
  considerChipWidth = false,
  direction = 'row',
}) => {
  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(value.length);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (containerRef.current && hideExtra) {
        const containerWidth = containerRef.current.parentElement.clientWidth;
        let totalWidth = 0;

        let count = 0;
        Array.from(containerRef.current.children).forEach((child, index) => {
          totalWidth += child.offsetWidth + 8; // gap = 8px
          // 39px - chip width
          const spaceForChip = considerChipWidth && index !== value.length - 1 ? 39 : 0;
          if (totalWidth + spaceForChip < containerWidth) {
            count = index + 1;
          }
        });

        setVisibleCount(count);
        setHiddenCount(value.length - count);
      } else {
        setVisibleCount(value.length);
      }
    };

    updateVisibleCount();
    // maxWidth is added to the dependency array for the component renderer
    // when it is in the middle of an autocomplete in a grid and the user changes the column width
  }, [value, hideExtra, considerChipWidth, maxWidth]);

  return (
    <Stack
      ref={containerRef}
      direction={direction}
      gap={1}
      flexWrap={hideExtra ? 'nowrap' : 'wrap'}
      maxWidth={maxWidth}
    >
      {value.map(({ approvers, status = 'draft', flowType }, i) => {
        const isSingleApprover = approvers.length === 1;
        const avatar =
          isSingleApprover && !withStatus && !collapseAll && !expandAll ? (
            <Avatar alt={approvers[0].fullName} src={approvers[0].picture} />
          ) : null;
        const label = getLabel({
          isSingleApprover,
          withStatus,
          expandAll,
          approvers,
          status,
          flowType,
          collapseAll,
          direction,
        });

        return (
          <Stack key={i} direction={direction} flexWrap="nowrap" gap={1}>
            {visibleCount === 0 && i === 0 && <Chip label={`+${hiddenCount}`} />}
            <Approver
              avatar={avatar}
              status={withStatus ? status : 'draft'}
              sx={{ opacity: visibleCount > i ? 1 : 0 }}
              label={label}
              variant="outlined"
              arrowDirection={direction}
              hasArrow={i + 1 !== value.length}
              isLevel
            />
            {visibleCount !== value.length && visibleCount !== 0 && i === visibleCount - 1 && (
              <Chip label={`+${hiddenCount}`} />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ApproverLevels;
