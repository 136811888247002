import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';
import { rtlEnable } from 'domain/env';
import { getIndicateWarningByDocSelector } from 'domain/documents';
import { getRowStatus } from 'components/AgGrid/helpers';
import { useIntl } from 'react-intl';
import CONST from 'domain/documents/constants';
import { createLocaleTextFunc, createTranslationsList } from 'components/AgGrid/helpers/translations';

const mapPropsToState = (state) => ({
  isGranted: ACL.isGranted(state),
  isRtl: rtlEnable(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
});

const useAgGridManualConfig = () => {
  const { isRtl, getIndicateWarningByDoc } = useSelector(mapPropsToState);
  const intl = useIntl();

  const isScheduledAcceptanceRow = useCallback(
    (params) =>
      params?.data?.rowDocumentData.tags
        ? params.data.rowDocumentData.tags.has(CONST.tags.isScheduledAcceptance)
        : false,
    [],
  );

  const rowClassRules = useMemo(
    () => ({
      'row-has-error': (params) => getRowStatus(params) === 'error',
      'row-has-warning': (params) =>
        getRowStatus(params) === 'warning' && getIndicateWarningByDoc(params?.data?.rowDocumentData),
      'row-publish-scheduling': (params) => isScheduledAcceptanceRow(params),
    }),
    [getIndicateWarningByDoc, isScheduledAcceptanceRow],
  );

  const translations = useMemo(() => createTranslationsList(intl), [intl]);
  const getLocaleText = useMemo(() => createLocaleTextFunc(translations), [translations]);

  return {
    enableRtl: isRtl,
    rowClassRules,
    getLocaleText,
  };
};

export default useAgGridManualConfig;
