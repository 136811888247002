// @flow
import React, { useMemo, useCallback } from 'react';

import FlexibleList from './FlexibleList';
import MuiAlert from '@mui/material/Alert';
import ReplayIcon from '@mui/icons-material/Replay';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CONST from 'domain/documents/constants';
import get from 'lodash/get';
import { errorStatusList, ID_FOR_DETECT_CELL_OVERFLOW } from 'components/AgGrid/helpers';
import { SYMBOL_FOR_EMPTY_CELL } from 'components/AgGrid/Documents/useAgGridColumnTypes';
import { alpha } from '@mui/material';

import type { GridItemType } from 'domain/documents/types.js.flow';

const severityColors = (theme) => ({
  error: theme.palette.error.main,
  warning: theme.palette.warning.main,
  info: theme.palette.info.main,
});

const Alert = styled(MuiAlert)(({ theme, severity }) => ({
  overflow: 'hidden',
  width: '100%',
  padding: theme.spacing(0.25, 1),
  backgroundColor: alpha(severityColors(theme)[severity], theme.palette.action.activatedOpacity),
  fontSize: 'inherit',
  '& .MuiAlert-message': {
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
  },
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
  '& .MuiAlert-icon': {
    marginRight: theme.spacing(1),
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 0),
  },
}));

type Props = {|
  data: GridItemType,
  value: (string | number)[],
  valueFormatted: ?string | number,
  colDef: {| field: 'string' |},
|};

const AlertCellRenderer = ({ data, value, valueFormatted, colDef: { field: defaultField } = {} }: Props) => {
  const currentValue = useMemo(
    () =>
      value && value.length
        ? value
        : [valueFormatted && valueFormatted.length ? valueFormatted : SYMBOL_FOR_EMPTY_CELL],
    [value, valueFormatted],
  );
  const errorLevel = useMemo(() => {
    const field = defaultField.split('.')[0];
    return get(data, `${field}.error_level`);
  }, [defaultField, data]);

  // TODO: ag-grid DA-14628 - scheduled status displayed in status column, not (extraSelect) Warning column now
  //  let's comment out code for the scheduled behavior, and in the future (maybe) move the UI to the status column
  // const isScheduledAcceptance = useMemo(() => data.rowDocumentData.tags.has(CONST.tags.isScheduledAcceptance), [data]);
  // const isScheduledApproval = useMemo(() => data.rowDocumentData.tags.has(CONST.tags.isScheduledApproval), [data]);

  const status = errorStatusList.get(errorLevel) || 'default';
  // (isScheduledAcceptance && 'pending') ||
  // (isScheduledApproval && 'pending') ||

  const renderList = useCallback(
    (texts: typeof currentValue) =>
      texts.length > 1 ? (
        <FlexibleList texts={texts} />
      ) : (
        <Typography textOverflow="ellipsis" overflow="hidden" id={ID_FOR_DETECT_CELL_OVERFLOW}>
          {texts[0]}
        </Typography>
      ),
    [currentValue],
  );

  const renders = {
    error: () => (
      <Alert severity="error" variant="outlined">
        {renderList(currentValue)}
      </Alert>
    ),
    warning: () => (
      <Alert severity="warning" variant="outlined">
        {renderList(currentValue)}
      </Alert>
    ),
    pending: () => (
      <Alert severity="info" variant="outlined" icon={<ReplayIcon fontSize="inherit" />}>
        <Typography variant="inherit" textOverflow="ellipsis" overflow="hidden" id={ID_FOR_DETECT_CELL_OVERFLOW}>
          {currentValue[0]}
        </Typography>
      </Alert>
    ),
    default: () => (
      <Box flex={1} overflow="hidden">
        <Typography variant="inherit" textOverflow="ellipsis" overflow="hidden" id={ID_FOR_DETECT_CELL_OVERFLOW}>
          {currentValue[0]}
        </Typography>
      </Box>
    ),
  };

  const render = get(renders, status, () => null);
  return (
    <Stack width="100%" height="100%" direction="row" justifyContent="start" alignItems="center">
      {render()}
    </Stack>
  );
};
export default AlertCellRenderer;
