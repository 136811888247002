/* @flow */
import * as t from 'typed-contracts';
import { type ExtractType, validate } from 'lib/contracts';

export * from './devices/contracts';

const ROLE = t.isUnion(
  'accountant',
  'limited-accountant',
  'restricted-accountant',
  'user',
  'confidential-user',
  'restricted-user',
  'confidential-restricted-user',
  'supplier',
);

const CONTACT_SHAPE = {
  email: t.isString.maybe,
  first_name: t.isString.maybe,
  last_name: t.isString.maybe,
};

const finInfoAttrTypes = {
  status: t.isString,
  vendor: t.isString,
  expenseType: t.isString,
  acc_status: t.isString,
  currency: t.isString,
  details: t.isString,
  provider: t.isString,
  date: t.isString,
  doc_finID: t.isString,
  total: t.isNumber,
  type: t.isString,
  vat: t.isNumber,
  transactionType: t.isString,
};

const nodesTypes = {
  id: t.isString,
  status: t.isString,
  updated: t.isString,
  userid: t.isString,
  username: t.isString,
};

const approvalLevelNodesTypes = {
  status: t.isString,
  updated: t.isString.maybe,
  user_id: t.isString,
  username: t.isString,
};

const approvalLevelsTypes = {
  flow_type: t.isString,
  id: t.isString,
  min_amount: t.isString,
  min_approvers: t.isString,
  next: t.isString,
  nodes: t.isArray(t.isObject(approvalLevelNodesTypes)),
  status: t.isString,
};

const documentAttrTypes = {
  created: t.isString,
  details: t.isString,
  documentID: t.isString,
  finInfo: t.isArray(t.isObject(finInfoAttrTypes)).maybe,
  linkid: t.isString,
  ltext: t.isString,
  notes: t.isString,
  notes_color: t.isString,
  reason: t.isString,
  rootCategory: t.isNumber.maybe,
  isConfidential: t.isBoolean.maybe,
  tags: t.isArray(t.isString),
  versionID: t.isString.maybe,
  viewinfo: t.isObject({ pages: t.isNumber, rotations: t.isArray(t.isNumber) }),
  sign: t.isObject({
    sign_position: t.isObject({
      ll: t.isArray(t.isNumber, t.isNumber).maybe,
      ur: t.isArray(t.isNumber, t.isNumber).maybe,
      rotation: t.isNumber.maybe,
    }),
  }),
  approvals: t.isObject({
    advanced_group_id: t.isString,
    creator: t.isString.maybe,
    editable: t.isBoolean,
    error: t.isString.maybe,
    group_id: t.isString,
    levels: t.isArray(t.isObject(approvalLevelsTypes)).maybe,
    next: t.isString.maybe,
    next_level: t.isString.maybe,
    nodes: t.isArray(t.isObject(nodesTypes)).maybe,
    rejectMessage: t.isString.maybe,
    rejectReason: t.isString.maybe,
    rejectReasons: t.isObject({
      assigment: t.isString,
      other: t.isString,
    }),
    signature: t.isString.maybe,
    status: t.isString.maybe,
    updated: t.isString.maybe,
  }),
};

const userCredentials = t.isObject({
  dokkaToken: t.isString,
  role: t.isUnion(ROLE, ''),
  is_authorized: t.isBoolean,
  last_phone_numbers: t.isString.maybe,
  reset_password_token: t.isString.maybe,
})('userCredentials');

export const validateUserCredentials = validate(userCredentials);

const orgBot = t.isObject({
  userID: t.isString.maybe,
  displayName: t.isString.maybe,
  picture: t.isString.maybe,
});

const APUserProfile = t.isObject({
  chatToken: t.isString,
  displayName: t.isString,
  firstName: t.isString.maybe,
  isSuperUser: t.isBoolean,
  isDokkaSupport: t.isBoolean,
  lastName: t.isString.maybe,
  organizationId: t.isString.maybe,
  picture: t.isString.maybe,
  role: ROLE,
  userGUID: t.isString,
  userID: t.isString,
  showMainTour: t.isBoolean,
  organizationBot: orgBot,
  allowFreshdesk: t.isBool,
  email: t.string.maybe,
  name: t.string.maybe,
  title: t.string.maybe,
  isAdmin: t.isBoolean.maybe,
}).maybe;

const MCUserProfile = t.isObject({
  chatToken: t.isString,
  role: t.isString,
  userID: t.isString,
  email: t.string.maybe,
  name: t.string.maybe,
  title: t.string.maybe,
  isAdmin: t.isBoolean.maybe,
}).maybe;

const userProfile = t.isObject({
  AP: t.isObject({
    appName: t.isString,
    appUrl: t.isString,
    active: t.isBoolean,
    profile: APUserProfile,
  }).maybe,
  MC: t.isObject({
    appName: t.isString,
    appUrl: t.isString,
    active: t.isBoolean,
    profile: MCUserProfile,
  }).maybe,
})('userProfile');

export const validateUserProfile = validate(userProfile);
export type OrgBotType = ExtractType<typeof orgBot>;

const messagesList = t.isArray(
  t.isObject({
    id: t.isString,
    type: t.isString,
    text: t.isString,
  }),
)('messagesList');

export const validateMessagesList = validate(messagesList);

const dashboardState = t.isObject({
  average: t.isObject({
    fin: t.isNumber,
    general: t.isNumber,
  }),
  completed: t.isObject({
    fin: t.isNumber,
    general: t.isNumber,
  }),
  ready: t.isObject({
    fin: t.isNumber,
    general: t.isNumber,
  }),
  total: t.isObject({
    fin: t.isNumber,
    general: t.isNumber,
  }),
  processing: t.isNumber,
})('dashboardState');

export const validateDashboardState = validate(dashboardState);

const search = t.isObject({
  count: t.isNumber,
  pageToken: t.isString.maybe,
  items: t.isArray(t.isObject(documentAttrTypes)),
})('search');

export const validateSearch = validate(search);

const chatPeers = t.isArray(
  t.isObject({
    displayName: t.isString,
    picture: t.isString.maybe,
    role: ROLE,
    userID: t.isString,
    isSupport: t.isBoolean,
  }),
)('chatPeers');

export const validateChatPeers = validate(chatPeers);

const searchFavorites = t.isObject({
  items: t.isArray(
    t.isObject({
      ID: t.isString.maybe,
      filter: t.isString.maybe,
      label: t.isString,
      query: t.isString.maybe,
      type: t.isString,
    }),
  ),
})('searchFavorites');

export const validateSearchFavorites = validate(searchFavorites);

const documentMetadata = t.isObject(documentAttrTypes)('documentMetadata');

export const validateDocumentMetadata = validate(documentMetadata);
export type DocumentMetadataType = ExtractType<typeof documentMetadata>;

const documentsBulkUpdateResponse = t.isObject({
  success: t.isArray(t.isString),
  ignored: t.isArray(t.isString),
  documents: t.isArray(t.isObject(documentAttrTypes)),
  error: t.isString,
})('documentsBulkUpdateResponse');

export const validateDocumentsBulkUpdateResponse = validate(documentsBulkUpdateResponse);

const JESet = t.isObject({
  value: t.isString.maybe,
});

const JECellStyle = t.isObject({
  color: t.isString.maybe,
  fontFamily: t.isUnion(t.isString, t.isArray(t.isString)).maybe,
  fontStyle: t.isUnion(t.isLiteral('italic'), t.isLiteral('normal')).maybe,
  fontWeight: t.isNumber.maybe,
  fontSize: t.isNumber.maybe,
  backgroundColor: t.isString.maybe,
});

const JEDescription = t.isObject({
  bold: t.isBoolean,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  text: t.isString,
  type: t.isLiteral('description'),
  style: JECellStyle.maybe,
  headerID: t.isString.maybe,
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const JEIndexList = t.isObject({
  bold: t.isBoolean,
  isCreatable: t.isBoolean.maybe,
  mandatory: t.isBoolean.maybe,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  list: t.isObjectOf(
    t.isObject({
      DisplayName: t.isString,
      Name: t.isUnion(t.isString, t.isNumber).maybe,
      Type: t.isString.maybe,
      Currency: t.isUnion(t.isString, t.isNumber).maybe,
    }).optional,
  ).maybe,
  set: JESet,
  type: t.isUnion(t.isLiteral('index_list'), t.isLiteral('paginated_list')),
  style: JECellStyle.maybe,
  hint: t.isString.maybe,
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const JEString = t.isObject({
  bold: t.isBoolean,
  mandatory: t.isBoolean.maybe,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  set: JESet,
  type: t.isLiteral('string'),
  style: JECellStyle.maybe,
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const JEFloat = t.isObject({
  bold: t.isBoolean,
  mandatory: t.isBoolean.maybe,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  set: JESet,
  type: t.isLiteral('float'),
  style: JECellStyle.maybe,
});

const JEGeneratedString = t.isObject({
  bold: t.isBoolean,
  hint: t.isString.maybe,
  init_value: t.isString.maybe,
  lenght: t.isNumber,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  set: JESet,
  style: JECellStyle.maybe,
  type: t.isLiteral('string_auto_generated'),
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const JECheckBox = t.isObject({
  bold: t.isBoolean,
  hint: t.isString.maybe,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  set: t.isObject({ value: t.isBoolean.maybe }),
  style: JECellStyle.maybe,
  type: t.isLiteral('checkbox'),
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const JEReconciliation = t.isObject({
  bold: t.isBoolean,
  hint: t.isString.maybe,
  mandatory: t.isBoolean.maybe,
  name: t.isString,
  readonly: t.isBoolean,
  return: t.isBoolean,
  set: t.isObject({
    value: t.isUnion(
      'Reconciled',
      'Ignored',
      'Match',
      'NoMatchCanCrate',
      'NoMatchCantCrate',
      'NoBankAccount',
      t.isNull,
    ),
  }),
  style: JECellStyle.maybe,
  type: t.isLiteral('reconciliation'),
  _row: t.isNumber.maybe,
  _col: t.isString.maybe,
});

const messageLevel = t.isUnion(t.isLiteral('warning'), t.isLiteral('error'), t.isLiteral('info'));

const journalEntry = {
  entries: t.isObjectOf(
    t.isUnion(JEDescription, JEIndexList, JEString, JEFloat, JEGeneratedString, JECheckBox, JEReconciliation),
  ),
  includes: t.isObjectOf(
    t.isObjectOf(
      t.isObjectOf({
        DisplayName: t.isString,
        Name: t.isUnion(t.isString, t.isNumber).maybe,
        Type: t.isString.maybe,
        Currency: t.isUnion(t.isString, t.isNumber).maybe,
      }).optional,
    ),
  ).maybe,

  messages: t.isArray(
    t.isObject({
      level: messageLevel,
      text: t.isString,
      type: t.isString,
    }).optional,
  ),
  publicationWarnings: t.isArray(t.isString).maybe,
  jeName: t.isString,
  jeType: t.isString,
  jeLayout: t.isString,
  jeInvoiceType: t.isString,
  header_settings: t.isArray(t.isNumber).maybe,
  header_lines_number: t.isNumber,
  line_items: t.isArray(t.isNumber).maybe,
  lines: t.isArray(t.isNumber).maybe,
  row_messages: t.isArray(
    t.isObject({
      row: t.isNumber,
      message: t.isString,
      type: messageLevel,
    }),
  ).maybe,
  summary: t.isArray(t.isNumber).maybe,
  initial_hidden_columns: t.isArray(t.isString).maybe,
  not_hidden_columns: t.isArray(t.isString).maybe,
  reconcile_column: t.isString.maybe,
  pinned_column: t.isString.maybe,
  amount_column: t.isString.maybe,
  balance_column: t.isString.maybe,
  transaction_type_column: t.isString.maybe,
  date_column: t.isString.maybe,
  description_column: t.isString.maybe,
  txn_id_column: t.isString.maybe,
  error_from_erp: t.isString.maybe,
  currentPage: t.isNumber,
  pageCount: t.isNumber,
  lineCount: t.isNumber,
  line_items_data: t.isObjectOf(
    t.isObject({
      line_id: t.isString,
      line_request_status: t.isUnion(t.isLiteral('read'), t.isLiteral('unread'), t.isNull),
    }),
  ).maybe,
  matchedLines: t.isObjectOf(
    t.isArray(
      t.isObject({
        line: t.isNumber,
        status: t.isString,
      }),
    ),
  ),
  two_way_match: t.isBoolean,
};

const journalEntryContainer = t.isObject(journalEntry)('journalEntry');

export type JournalEntryType = ExtractType<typeof journalEntryContainer>;

const searchGetHints = t.isArray(
  t.isObjectOf(
    t.isObject({
      score: t.isNumber,
    }),
  ),
)('searchGetHints');

export const validateSearchGetHints = validate(searchGetHints);

const updateNotes = t.isObject(documentAttrTypes)('updateNotes');

export const validateUpdateNotes = validate(updateNotes);

export const validateRemoveNotes = validate(updateNotes);

// ======= Organization ===================

const organizationShape = {
  city: t.isString.maybe,
  contact: t.isObject({
    ...CONTACT_SHAPE,
    phone: t.isString.maybe,
  }).maybe,
  country: t.isString.maybe,
  id: t.isString,
  name: t.isString,
  picture: t.isString,
  registration_number: t.isString.maybe,
  street: t.isString.maybe,
  vat_number: t.isUnion(t.isNumber, t.isString).maybe,
  is_paid: t.isBoolean.maybe,
  amounts_representation: t.isString,
};

const organizations = t.isObject(organizationShape)('organizations');

export const validateOrganizations = validate(organizations);

export const validateCreateOrganization = validate(organizations);

const updateOrganization = t.isObject({
  ...organizationShape,
  valid: t.isBoolean.maybe,
  website: t.isString.maybe,
})('updateOrganization');
export const validateUpdateOrganization = validate(updateOrganization);

// ==== Org Feature ================

const organizationFeatureSetItemType = t.isObject({
  value: t.isBoolean,
  display_name: t.isString,
  state: t.isUnion('hidden', 'disabled', 'enabled'),
  help: t.isUnion(t.isString, t.isNull),
  show_hint: t.isBoolean,
}).maybe;

const companyFeatureSetItemType = t.isObject({
  value: t.isBoolean,
  display_name: t.isString,
  state: t.isUnion('hidden', 'disabled', 'enabled'),
  help: t.isUnion(t.isString, t.isNull),
  show_hint: t.isBoolean,
}).maybe;

const organizationFeaturesShape = {
  document_backup: organizationFeatureSetItemType,
  approval_stamp: organizationFeatureSetItemType,
};

const organizationsFeatures = t.isObject(organizationFeaturesShape)('organizationsFeatures');
export const validateOrganizationsFeatures = validate(organizationsFeatures);

export type OrganizationsFeaturesDataType = ExtractType<typeof organizationsFeatures>;

// ==== Org Backup Connections ================
// [].backup
export const backupConnectionTypes = t.isUnion('GOOGLE_DRIVE', 'DROPBOX', 'ONE_DRIVE', 'I_CLOUD', 'BOX');
export const backupConnectionStatus = t.isUnion('disconnected', 'syncing', 'synced', 'fail');

const organizationBackupConnectionShape = {
  type: backupConnectionTypes,
  connection: backupConnectionStatus,
  name: t.isString,
  username: t.string.maybe,
  error: t.string.maybe,
};

const organizationBackupConnection = t.isObject(organizationBackupConnectionShape);
export type OrgBackupConnectionDataType = ExtractType<typeof organizationBackupConnection>;

// []
const organizationBackupConnections = t.isArray(organizationBackupConnection);
export const validateOrgBackupConnections = validate(organizationBackupConnections);

// ==== Company ================

const companyShape = {
  city: t.isString.maybe,
  confidential_email_box: t.isString.maybe,
  contact: t.isObject({
    ...CONTACT_SHAPE,
    phone: t.isString.maybe,
  }),
  country: t.isString.maybe,
  currency: t.isString.maybe,
  email_box: t.isString,
  id: t.isString,
  name: t.isString,
  organization_id: t.isString,
  picture: t.isString,
  registration_number: t.isString.maybe,
  street: t.isString.maybe,
  vat_number: t.isString.maybe,
  is_connected_to_erp: t.isBoolean,
  pending_approval: t.isNumber.maybe,
  amounts_representation: t.isString,
};

const company = t.isObject(companyShape)('company');

export const validateCompany = validate(company);
export type CompanyType = ExtractType<typeof company>;

const companies = t.isArray(company);

export const validateCompanies = validate(companies);
export type CompaniesType = ExtractType<typeof companies>;

const updateCompany = t.isObject({
  ...companyShape,
  contact: t.isObject({}).maybe,
  website: t.isString.maybe,
  valid: t.isBoolean.maybe,
})('updateCompany');

export const validateUpdateCompany = validate(updateCompany);

const createCompany = t.isObject(companyShape)('createCompany');
export const validateCreateCompany = validate(createCompany);
export type CreateCompanyType = ExtractType<typeof createCompany>;

const updateCompanyLogo = t.isObject({
  picture: t.isString,
})('updateCompanyLogo');
export const validateUpdateCompanyLogo = validate(updateCompanyLogo);

const setCompanyFeatures = t.isObject({
  fin: companyFeatureSetItemType,
  new_chat: companyFeatureSetItemType,
  approvals: companyFeatureSetItemType,
  approvals_form: companyFeatureSetItemType,
  is_translate_enable: companyFeatureSetItemType,
  supplier_form: companyFeatureSetItemType,
  prototype_feature_preset: companyFeatureSetItemType,
  isPushAsPrimaryChatSrc: companyFeatureSetItemType,
  erp_ssl_verification: companyFeatureSetItemType,
  new_brain: companyFeatureSetItemType,
  cached_lists: companyFeatureSetItemType,
  approval_flow_from_brain: companyFeatureSetItemType,
  hash_export: companyFeatureSetItemType,
  paid_unpaid: companyFeatureSetItemType,
  lock_accept_for_not_approved_docs: companyFeatureSetItemType,
  enhanced_supplier_validation: companyFeatureSetItemType,
  show_not_assigned_category: companyFeatureSetItemType,
  reply_on_upload: companyFeatureSetItemType,
  enable_client_chat: companyFeatureSetItemType,
  linked_folder_transition: companyFeatureSetItemType,
  allow_bookkeeper_to_change_approval_flow: companyFeatureSetItemType,
  link_email_documents: companyFeatureSetItemType,
  config_default_tags: companyFeatureSetItemType,
  approval_stamp: companyFeatureSetItemType,
  approval_date_on_stamp: companyFeatureSetItemType,
  erp: t.isObject({
    DEFAULT_ERP: t.isBoolean,
    FOX: t.isBoolean,
    HASH: t.isBoolean,
    NETSUITE: t.isBoolean,
    PRIORITY_PRO: t.isBoolean,
    PRIORITY_PRO_SQL: t.isBoolean,
    QBD: t.isBoolean,
    QBO: t.isBoolean,
    SAGE: t.isBoolean,
    SAGE_SA: t.isBoolean,
    SAP: t.isBoolean,
    XERO: t.isBoolean,
    ZOHO: t.isBoolean,
  }),
})('setCompanyFeatures');
export const validateSetCompanyFeatures = validate(setCompanyFeatures);

const deleteCompany = t.isUnion('OK')('deleteCompany');
export const validateDeleteCompany = validate(deleteCompany);

const deleteUser = t.isUnion('OK')('deleteUser');
export const validateDeleteUser = validate(deleteUser);

const companyList = t.isArray(
  t.isObject({
    checksum: t.isString,
    cname: t.isString,
    corresp: t.isBoolean,
    dateFormat: t.isString,
    description: t.isString,
    email: t.isString,
    'email.confidential': t.isString,
    generalParams: t.isObject({
      fin: companyFeatureSetItemType,
    }).maybe,
    id: t.isString,
    is_connected_to_erp: t.isBoolean,
    language: t.isString.maybe,
    logo: t.isString,
    organizationId: t.isString.maybe,
    pinned: t.isBoolean,
    splashLogo: t.isString.maybe,
    unprocessed: t.isNumber.maybe,
    pending_approval: t.isNumber.maybe,
    whatsapp_phone: t.isString,
  }),
)('companyList');

export const validateCompanyList = validate(companyList);

// ====================================

const userShape = {
  ...CONTACT_SHAPE,
  phone: t.isString.maybe,
  id: t.isString,
  role: ROLE,
  title: t.isString,
  companies_id: t.isArray(t.isString),
  admin: t.isBool,
};

const users = t.isArray(t.isObject(userShape))('users');

export const validateUsers = validate(users);

const user = t.isObject(userShape)('user');

export const validateUser = validate(user);
export type UserType = ExtractType<typeof user>;

const assignOrgUser = t.isObject(userShape)('assignOrgUser');
export const validateAssignOrgUser = validate(assignOrgUser);

const revokeOrgUser = t.isObject(userShape)('revokeOrgUser');
export const validateRevokeOrgUser = validate(revokeOrgUser);

// =============================================

const tokensShape = {
  name: t.isString,
  password: t.isString,
  token_name: t.isString,
};

const prefSignShape = {
  guid: t.isString,
  tokens: t.isArray(t.isObject(tokensShape)),
};

const getSignings = t.isObject(prefSignShape)('getSignings');

const connectionShape = t.isObject({
  status: t.isString,
  details: t.isObject({}),
});

const connectionsShape = {
  DEFAULT_ERP: connectionShape,
  FOX: connectionShape,
  HASH: connectionShape,
  PRIORITY_PRO: connectionShape,
  PRIORITY_PRO_SQL: connectionShape,
  QBO: connectionShape,
  QBD: connectionShape,
  SAGE: connectionShape,
  SAGE_SA: connectionShape,
  XERO: connectionShape,
};

const validateConnections = t.isObject(connectionsShape)('validateConnections');

const setGuidSignings = t.isObject({
  guid: t.isString,
})('setGuidSignings');

export const validateGetSignings = validate(getSignings);
export const validateGetCompanyConnections = validate(validateConnections);
export type ERPConnectionsType = ExtractType<typeof validateConnections>;
export const validateSetSigningsGuid = validate(setGuidSignings);

const signTokensShape = {
  name: t.isString,
  password: t.isString,
  permissions: t.isObject({
    roles: t.isArray(ROLE).maybe,
    users: t.isArray(t.isString).maybe,
  }).optional,
};

const companyPreferences = t.isUnion(
  t.isObject({
    guid: t.isString,
    token1: t.isObject(signTokensShape),
    token2: t.isObject(signTokensShape),
  }),
  t.isObject({}),
)('companyPreferences');
export const validateCompanyPreferences = validate(companyPreferences);

const updateSignings = t.isObject(tokensShape)('updateSignings');
export const validateUpdateSignings = validate(updateSignings);

const removeSignings = t.isUnion('OK')('removeSignings');
export const validateRemoveSignings = validate(removeSignings);

const emptyContracts = t.isObjectOf(t.isString)('emptyContracts');
export const validateEmptyContracts = validate(emptyContracts);

const erpSettingsContracts = t.isUnion(
  t.isArray(t.isObject({})),
  t.isObject({
    warning: t.isString,
  }),
)('erpSettings');
export const validateERPSettingsContracts = validate(erpSettingsContracts);

const duplicateDocument = t.isUnion('OK')('duplicateDocument');
export const validateDuplicateDocument = validate(duplicateDocument);

const getDocuments = t.isObject({})('getDocuments');
export const validateGetDocuments = validate(getDocuments);

const removeDocuments = t.isObject({
  error: t.isString,
  documents: t.isArray(t.isObject({}).optional),
  ignored: t.isArray(t.isString),
  success: t.isArray(t.isString),
})('removeDocuments');
export const validateRemoveDocuments = validate(removeDocuments);

// getUnreadRequests

const unreadRequestLine = t.isObject({
  id: t.isString,
  row: t.isUnion(t.isString, t.isNumber),
  lastTimestamp: t.isString,
});

const unreadRequestDoc = t.isObject({
  documentId: t.isString,
  companyId: t.isString,
  lines: t.isArray(unreadRequestLine),
});

const unreadRequestDocs = t.isArray(unreadRequestDoc);

export const validateUnreadRequests = validate(unreadRequestDocs);
export type UnreadRequestLineType = ExtractType<typeof unreadRequestLine>;
export type UnreadRequestDocType = ExtractType<typeof unreadRequestDoc>;

const getEvents = t.isObject({
  eventHash: t.isString,
  nextNumPage: t.isString.maybe,
  data: t.isArray(
    t.isObject({
      details: t.isString.maybe,
      documentID: t.isString.maybe,
      id: t.isString,
      message: t.isString,
      timestamp: t.isString,
      userID: t.isString.maybe,
      username: t.isString.maybe,
      link: t.isUnion(
        t.isObject({
          type: t.isLiteral('document'),
          source: t.isObject({
            documentID: t.isString,
            exist: t.isBoolean,
          }),
        }),
        t.isObject({
          type: t.isLiteral('linked_document'),
          source: t.isObject({
            linkID: t.isString,
            confidential: t.isBoolean.maybe,
            exist: t.isBoolean,
            rootCategory: t.isNumber.maybe,
          }),
        }),
        t.isObject({
          type: t.isLiteral('company_chat'),
          source: t.isObject({
            channel_name: t.isString,
            channel_url: t.isString,
            companyID: t.isString,
          }),
        }),
        t.isObject({
          type: t.isLiteral('document_chat'),
          source: t.isObject({
            channel_name: t.isString,
            channel_url: t.isString,
            companyID: t.isString,
            documentID: t.isString,
          }),
        }),
        t.isObject({}),
      ),
    }),
  ),
})('getEvents');
export const validateGetEvents = validate(getEvents);
export type GetEventsType = ExtractType<typeof getEvents>;

const getEventFilters = t.isObject({
  types: t.isArray(
    t.isObject({
      label: t.isString,
      value: t.isString,
    }),
  ),
  users: t.isArray(
    t.isObject({
      label: t.isString,
      value: t.isString,
      picture: t.isString.maybe,
    }),
  ),
})('getEventFilters');
export const validateGetEventFilters = validate(getEventFilters);

const getCountOfNewEvents = t.isObject({
  countOfNewEvents: t.isNumber,
})('getCountOfNewEvents');
export const validateGetCountOfNewEvents = validate(getCountOfNewEvents);

const getCurrencies = t.isObjectOf(
  t.isObject({
    code: t.isString,
    name: t.isString,
    rounding: t.isNumber,
    symbol: t.isString,
    symbol_native: t.isString,
    decimal_digits: t.isNumber,
    name_plural: t.isString,
    favourite: t.isBoolean.maybe,
  }),
)('getCurrencies');
export const validateCurrencies = validate(getCurrencies);
export type GetCurrenciesType = ExtractType<typeof getCurrencies>;

const getTransactionErp = t.isUnion(
  t.isObject({
    transactionID: t.isUnion(t.isString, t.isNumber),
  }).maybe,
)('getTransactionErp');
export const validateTransactionErp = validate(getTransactionErp);

const activateErp = t.isUnion('OK')('activateErp');
export const validateActivateErp = validate(activateErp);

const acceptDocument = t.isObject({
  ...documentAttrTypes,
  details: t.isUnion(t.isString, t.isObject(journalEntry)),

  sourceID: t.isString.maybe,
})('acceptDocument');
export const validateAcceptDocument = validate(acceptDocument);

const updateTags = t.isObject({
  ...documentAttrTypes,
  sourceID: t.isString.maybe,
})('updateTags');
export const validateUpdateTags = validate(updateTags);

const reconcileStatistic = t.isArray(
  t.isObject({
    count: t.isNumber,
    text: t.isString,
  }),
)('reconcileStatistic');
export const validateReconcileStatistic = validate(reconcileStatistic);

// GOOGLE SEARCH CONTRACT
// ======================================
const googleSearchItem = t.isObject({
  title: t.isString,
  link: t.isString,
  snippet: t.isString,
});

const googleSearchItems = t.isArray(googleSearchItem)('googleSearchItem');
export const validateGoogleSearch = validate(googleSearchItems);

export const latestDocsUpdateTimestamp = t.isObj({
  timeStamp: t.isUnion(t.isString, t.isNull),
})('latestDocsUpdateTimestamp');

// NOTIFICATIONS CONTRACT
// ======================================
const notifications = t.isObject({
  approval_notification: t.isObject({
    notification_type: t.isString,
    hours: t.isNumber,
    minutes: t.isNumber,
    timezone: t.isString,
    notify_me: t.isUnion('instantly', 'per_hour', 'per_day', 'never'),
    remind_me: t.isBoolean,
    frequency_days: t.isNumber,
  }),
  chat_notification: t.isObject({
    chat_to_mail: t.isBoolean,
  }),
  slack_notification: t.isObject({
    notify: t.isBoolean,
    connected: t.isBoolean,
  }),
});
export const validateNotifications = validate(notifications);
export type TNotifications = ExtractType<typeof notifications>; // TODO: rewrite type for notifications

// DOCUMENT HOTKEYS CONTRACT
// ======================================
const documentHotkeyList = t.isArray(
  t.isObject({
    keys: t.isArray(t.isArray(t.isString)),
    description: t.isString,
    level: t.isString,
  }),
)('documentHotkeyList');
export const validateDocumentHotkeyList = validate(documentHotkeyList);

// USER FEATURES CONTRACT
// ======================================
const userFeatures = t.isObject({
  modal: t.isBoolean,
});
export const validateUserFeatures = validate(userFeatures);

// WORKSPACE GRID PRESET CONTRACT
// ======================================

const wsGridPreset = t.isObject({
  id: t.isString,
  name: t.isString,
  config: t.isString,
  all: t.isString,
});
export type WsGridPresetT = ExtractType<typeof wsGridPreset>;

const wsGridPresetResponse = t.isObject({
  result: wsGridPreset,
  error: t.isString,
});

export const validateWsGridPresetResponse = validate(wsGridPresetResponse);

const wsGridPresets = t.isArray(wsGridPreset);
export type WsGridPresetsT = ExtractType<typeof wsGridPresets>;

const wsGridPresetsResponse = t.isObject({
  result: wsGridPresets,
  error: t.isString,
});

export const validateWsGridPresetsResponse = validate(wsGridPresetsResponse);

const insightsDocumentVendor = t.isObject({
  display: t.isString.maybe,
  value: t.isString.maybe,
})('insightsDocumentVendor');
export const validateInsightsDocumentVendor = validate(insightsDocumentVendor);
